<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="400">
    <v-card-title class="justify-center">Sign Up</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="E-mail"
              placeholder="E-mail"
              solo
              required
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              solo
              required
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-row d-flex justify="center">
            <v-btn
              class="d-flex justify-center mb-6"
              color="primary"
              min-width="100%"
              @click="emailLogin"
            >
              Sign Up with SendMore
            </v-btn>
            <v-btn
              class="d-flex justify-center mb-6"
              color="secondary"
              min-width="100%"
              to="/login"
            >
              Sign In
            </v-btn>
          </v-row>
        </v-card-actions>
        <v-divider class="mt-2 mb-6"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#4285F4"
              @click="googleLogin"
            >
              <v-icon dark> mdi-google </v-icon>&nbsp; Sign Up using Google
            </v-btn>
          </v-col>
        </v-row>
        <v-row d-flex justify="center" style="display: none;">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#4267B2"
              @click="googleLogin"
            >
              <v-icon dark> mdi-facebook </v-icon>&nbsp; Sign Up using Facebook
            </v-btn>
          </v-col>
        </v-row>
        <v-row d-flex justify="center" style="display: none;">
          <v-col>
            <v-btn
              class="justify-center"
              dark
              min-width="100%"
              color="#1DA1F2"
              @click="googleLogin"
            >
              <v-icon dark> mdi-twitter </v-icon>&nbsp; Sign Up using Twitter
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}

.v-application a {
  text-align: center;
  width: 100%;
  text-decoration: none;
  color: #22abe1;
  display: flex;
  justify-content: center;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";
import firebase from "firebase/compat/app";

export default {
  name: "Login",
  mixins: [hasuraService],
  methods: {
    googleLogin: function() {
      localStorage.removeItem("token");
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          console.log("googleLogin");
          this.$store.state.is_login = true;
          window.location.href = this.$store.state.next_route || "/seminar";
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
          this.$store.commit("set_snackbar", err.message);
        });
    },
    emailLogin: function() {
      // delete old token from localStorage
      localStorage.removeItem("token");
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          console.log("emailLogin", result);
          this.$store.state.is_login = true;
          window.location.href = this.$store.state.next_route || "/seminar";
        })
        .catch((error) => {
          this.$store.commit("set_snackbar", error.message);
        });
    },
    // googleLogin: function() {
    //   let provider = new firebase.auth.GoogleAuthProvider();
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then((result) => {
    //       console.log("googleLogin");
    //       this.$store.state.is_login = true;
    //       this.$router.push("/");
    //     })
    //     .catch((err) => {
    //       console.log(err); // This will give you all the information needed to further debug any errors
    //     });
    // },
    // submit: async function() {
    //   if (this.$refs.form.validate()) {
    //     this.loading = true;
    //     let result = await this.login(this.email, this.password);
    //     if (result.ok) {
    //       localStorage.setItem("token", result.jwt);
    //       setTimeout(() => {
    //         // we are using href to redirect to the next route
    //         // because there is a race condition
    //         window.location.href = this.$store.state.next_route || "/seminar";
    //       }, 50);
    //     } else {
    //       this.$store.commit("set_snackbar", result.reason);
    //       this.loading = false;
    //     }
    //   }
    // },
  },
  data: () => {
    return {
      loading: false,
      email: "",
      password: "",
      valid: true,
      req: [(v) => !!v || "Field is required"],
    };
  },
};
</script>
